<!--
 * @Author: your name
 * @Date: 2021-04-13 19:00:30
 * @LastEditTime: 2021-04-15 14:21:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\views\usermsg\index.vue
-->
<template>
  <div>
    <navigation msg="个人信息"  ></navigation>
    <div class="topmsgclass">
      <div>
        <div class="tx">头像</div>
        <div class="jl">使用真实的头像，可以增加您入职的几率</div>
      </div>
      <div v-if="userInfo.headimg_url=='' ">
        <img @click="goAvatar('')" v-if="userInfo.sex == '女' " class="userimg" src="../../assets/nv.jpg" alt="" />
        <img @click="goAvatar('')" v-else class="userimg" src="../../assets/user_1.jpg" alt="" />
      </div>
      <div v-else>
          <img @click="goAvatar(userInfo.headimg_url)" class="userimg" :src="userInfo.headimg_url" alt="" />
      </div>
    </div>
    <div class="xxc">
      <div>账户名/手机号</div>
      <div class="tms">
        <div>{{userInfo.phone}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>
    <div class="xxc" @click="goEditName">
      <div>姓名</div>
      <div class="tms">
        <div>{{userInfo.realname == '' ? "请输入" : userInfo.realname}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>
    <div class="xxc"  @click="showsex = true">
      <div>性别</div>
      <div class="tms">
        <div>{{ userInfo.sex == "" ? "请选择" : userInfo.sex }}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>
    <!-- 性别选择框 -->
    <van-action-sheet v-model="showsex" :actions="actions" @select="onSelect" />
    
    <div class="xxc">
      <div>出生年月</div>
      <div class="tms">
        <div @click="showdata=true">{{userInfo.birthday == "" ? "请选择" : userInfo.birthday}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>

    <!-- 出生年月选择框 -->
    <van-popup v-model="showdata" position="bottom" :style="{ height: '7.3rem' }">
      <van-datetime-picker
        v-model="currentDate"
        :min-date="minDate"
        type="date"
        title="选择年月日"
        @confirm="confirmdata"
        @cancel="canceldata"
      />
    </van-popup>

    <!-- <div class="xxc" @click="showNewIdentity">
      <div>最新身份</div>
      <div class="tms">
        <div>{{ userInfo.new_identity == "" ? "请选择" : userInfo.new_identity}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div> -->

    <!-- 最新身份弹出框 -->
    <!-- <van-popup v-model="popShow" position="bottom" :style="{ height: '5.6rem' }" >
     <div class="select">最新身份</div>
     <div class="day" @click="getType(1)">在职员工</div>
     <div class="night" @click="getType(2)">已离职</div>
    </van-popup> -->

    <div class="xxc" @click="goEditEmail">
      <div>邮箱</div>
      <div class="tms">
        <div>{{userInfo.email == "" ? "请输入" : userInfo.email}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>

    <div class="xxc" @click="goEditWx">
      <div>微信号</div>
      <div class="tms">
        <div>{{userInfo.wechat_num == "" ? "请输入" : userInfo.wechat_num}}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import { Toast } from "vant";
import getDate from "../../util/dateChange"


export default {
  components: {
    navigation,
  },
  data() {
    return {
      showsex: false,
      sexmsg: "请选择",
      actions: [{ name: "男" }, { name: "女" }],
      currentDate: new Date(),
      showdata:false, //日期选择
      userInfo:{},
      popShow:false,
      minDate:new Date(1970,1,1)
    };
  },
   mounted(){
    this.getInfo()
  },
  methods: {

    goAvatar(avatar){
      this.$router.push({path:"./avatar",query:{avatar:avatar,sex:this.userInfo.sex}})
    },

    getInfo(){
       this.$http.post('/user/v1/personal_info/getPersonalInfo',{reqType:"getPersonalInfo"})
    .then(
      (res)=>{
      let response = JSON.parse(res.data)
      // console.log(response)
      if(response.code == 0){
         this.userInfo = response.data.userInfo
        //  let userinfo = JSON.stringify(response.data.userInfo)
				//   sessionStorage.setItem("userinfo",userinfo)
      }else{
        Toast.fail(response.msg)
      }
     
      })
    .catch((e)=>{console.log(e)})
    },

    getType(type){
      var status;
      if(type == 1){
        status = "在职员工" 
        this.popShow = false
      }else{
        status = "已离职" 
        this.popShow = false
      }

      this.$http.post("/user/v1/personal_info/editPersonalInfo",{reqType:"editPersonalInfo",new_identity:status})
      .then((res)=>{
        let response = JSON.parse(res.data)
         if(response.code == 0){
           this.getInfo()
           Toast.success(response.msg)
         }else{
           Toast.fail(response.msg)
         }
      })
      .catch((e)=>{console.log(e)})
      
    },
    showNewIdentity(){
      this.popShow = true
    },
    goEditName(){
      let username = this.userInfo.realname
      this.$router.push({path:'./editName',query:{username:username,realname_auth:this.userInfo.realname_auth,}})
    },
    goEditEmail(){
      let email = this.userInfo.email
      this.$router.push({path:'./editEmail',query:{email:email}})
    },
    goEditWx(){
      let wechat_num = this.userInfo.wechat_num
       this.$router.push({path:'./editWx',query:{wechat_num:wechat_num}})
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.$http.post("/user/v1/personal_info/editPersonalInfo",{reqType:"editPersonalInfo",sex:item.name})
      .then((res)=>{
        console.log(res)
        let response = JSON.parse(res.data)
        if(response.code == 0){
          Toast.success(response.msg)
          this.getInfo()
        }else{
          Toast.fail(response.msg)
        }
      })
      .catch((e)=>{console.log(e)})
      this.showsex = false;
    },
    confirmdata(value){
      let date = getDate(value)
      this.$http.post("/user/v1/personal_info/editPersonalInfo",{reqType:"editPersonalInfo",birthday:date})
      .then((res)=>{
        let response = JSON.parse(res.data)
         if(response.code == 0){
            this.getInfo()
           Toast.success(response.msg)
         }else{
           Toast.fail(response.msg)
         }

      })
      .catch((e)=>{console.log(e)})
      // console.log(date)
      this.showdata = false;
    },
    canceldata(){
      this.showdata = false;
    }
  },
};
</script>
<style scoped>
.tms {
  display: flex;
  align-items: center;
}
.tms > div:first-child {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 0.48rem;
  margin-right: 0.2rem;
}
.xxc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.32rem 0.46rem;
  margin-bottom: 1px;
}
.xxc > div:first-child {
  font-size: 0.3rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.48rem;
}
.userimg {
  width: 1.18rem;
  height:1.18rem;
  border-radius:50%;
}
.topmsgclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.49rem 0.46rem;
  margin-bottom: 0.14rem;
}
.tx {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.56rem;
  text-align: left;
}
.jl {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55814;
  line-height: 0.33rem;
  margin-top: 0.14rem;
}

.select, .day, .night{
  text-align: center;
}
.select{
  font-size: 0.34rem;
  color: #333333;
  margin-bottom:0.71rem;
   margin-top:0.48rem;
}
.day, .night{
  font-size: 0.3rem;
  
  border-bottom:0.01rem solid #ddd;
  height: 1rem;
  line-height: 1rem;
  margin:0 0.39rem;
}
</style>